<script setup>
    import { ref, onMounted, onUnmounted, onBeforeUnmount, computed, onUpdated } from 'vue'
    import { reflectPreference, onClick } from '@/stores/helpers/themeToggle'
    import { vOnClickOutside } from '@vueuse/components'
    
    import router from '../router/routes';

    import { useIncentivesStore } from '@/stores/incentives'
    const incentivesStore = useIncentivesStore();

    import { useRatingsStore } from '@/stores/ratings';
    const ratingsStore = useRatingsStore();

    import { useBusinessPartnersStore } from '@/stores/businessPartners'
    const businessPartnersStore = useBusinessPartnersStore();
    
    import { useUserStore } from '@/stores/user'
    const userStore = useUserStore();

    import { usePerformanceStore } from '@/stores/performance'
    const performanceStore = usePerformanceStore();

    import { useFunctionsStore } from '@/stores/functions'
    const functionsStore = useFunctionsStore()

    const exceededToDos = computed(() => {
        if (userStore.currentSelection.scope!=="salesAgent") return null;
        
        // if (userStore.surname === 'vom Hofe') return null; // debug only
        const salesPitchFollowUps = businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#ToDo:Wiedervorlagen`);
        const salesPitchFeedbacks = businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#ToDo:VorOrtTermine`);
        return {
            both: (salesPitchFollowUps || []).length > userStore.maxToDos.followUp && (salesPitchFeedbacks || []).length > userStore.maxToDos.feedback,
            followUps: (salesPitchFollowUps || []).length > userStore.maxToDos.followUp,
            feedbacks: (salesPitchFeedbacks || []).length > userStore.maxToDos.feedback
        }
    });
  
    onMounted(() => {
        reflectPreference();
    });
    
    const handleLogOut = () => {
        localStorage.removeItem('rememberMe')
        localStorage.removeItem('email')
        localStorage.removeItem('password')
        router.push({ name: 'Login'});
    }
    
    // campaign management
    const handleChangeCampaign = async (campaign) => {
        userStore.currentSelection.campaign = campaign
        await userStore.selectCampaign(campaign.id)
        // console.log('changing campaign to ', campaign.name, 'in module', userStore.currentSelection.module)
        // if the user changes the campaign while accessing a businessPartner, the routing should return to the dashboard
        if (['BusinessPartnerList'].includes(userStore.currentSelection.module)) {
            router.push({ name: 'Dashboard'});
        }
    }

    // Toggle Burger Menu
    const isOpen = ref(false)
    const toggleBurgerMenu = () => {
        isOpen.value = !isOpen.value;
    }
    const handleClickOutside = (event) => {
        const target = event.target;
        const hamElement = document.querySelector('.ham');
        const themeElement = document.querySelector('.theme-wrapper');
        const campaignElement = document.querySelector('.campaign-select');
        if ( !hamElement.contains(target) && !themeElement?.contains(target) && (campaignElement && !campaignElement.contains(target)) && isOpen.value === true ) toggleBurgerMenu(); 
    };

    onBeforeUnmount(() => {
        document.removeEventListener('click', handleClickOutside);
    });

    const incentiveInformations = computed(() => {
        // only get the information on a salesAgent not on management
        if (userStore.isManagement || userStore.isSalesTeamManager) {
            return {
                bonus: 0,
                entitled: false,
                name: `${userStore.firstname} ${userStore.surname}`,
            }
        }
        
        const championsExists = incentivesStore.incentive?.salesAgents?.champions?.find((champion) => champion.highlightedPosition === true);
        if (!championsExists) {
            return {
                bonus: 0,
                entitled: false,
                name: `${userStore.firstname} ${userStore.surname}`,
            }
        } else {
            return {
                bonus: championsExists.salesBonus,
                entitled: championsExists.salesBonusEntitled,
                name: championsExists.salesAgentName,
            }
        }
    });

    function formattedSalesAgentName(name) {
    return name
        .toLowerCase() // Konvertiere in Kleinbuchstaben
        .replace(/ä/g, 'ae') // Ersetze ä durch ae
        .replace(/ö/g, 'oe') // Ersetze ö durch oe
        .replace(/ü/g, 'ue') // Ersetze ü durch ue
        .replace(/ß/g, 'ss') // Ersetze ß durch ss
        .replace(/\s+/g, '-') // Ersetze Leerzeichen durch -
        + '.webp'; // Füge die Dateiendung hinzu
    };

    const dropdownVisible = ref(false);

    const toggleDropdown = () => {
        dropdownVisible.value = !dropdownVisible.value;
    };

    const navigationRef = ref(null)
    function updateNavigationHeight() {
        if (navigationRef.value) {
            functionsStore.setNavigationHeight(navigationRef.value.getBoundingClientRect().height)
        }
    }

    onMounted(() => {
        updateNavigationHeight()
        window.addEventListener('resize', updateNavigationHeight)
    })

    onUpdated(() => {
        updateNavigationHeight()
    })

    onUnmounted(() => {
        window.removeEventListener('resize', updateNavigationHeight)
    })

</script>

<template>
    <header>
        <nav class="max-width" ref="navigationRef">
            <!-- Navigation Height: {{ functionsStore.navigationHeight }} -->
            <div class="wrapper-logo">
                <router-link to="/dashboard"><img width="64" height="64" src="@/assets/imgs/logos/sellwerk/sellwerk-favicon.svg" alt="Sellwerk Favicon"></router-link>
                <p>sma 2.0</p>
            </div>
            <div class="wrapper-actions">
                <div v-if="userStore.loginStatus==='success'" class="wrapper-campaign">
                    <div v-for="publication of userStore.currentSelection.campaign?.publicationList || []" :key="publication.id" class="wrapper-publication-single">
                        <img v-if="publication.startsWith('GS')" src="@/assets/imgs/logos/campaigns/Gelbe-Seiten-App-Icon.svg" alt="Logo Gelbe Seiten">
                        <img v-if="publication.startsWith('TB')" src="@/assets/imgs/logos/campaigns/Das-Telefonbuch-App-Icon.svg" alt="Logo Telefonbuch">
                        <img v-if="publication.startsWith('OE')" src="@/assets/imgs/logos/campaigns/Das-Oertliche-App-Icon.svg" alt="Logo Das Örtliche">
                        <span>{{ publication.split(' ')[1] }}</span>
                    </div>
                </div>
                <!-- <span class="divider"></span> -->
                <!-- <svg class="ham hamRotate ham8" viewBox="0 0 100 100" width="64" onclick="this.classList.toggle('active')"> -->
                <svg :class="['ham hamRotate ham8', {'active' : isOpen}]" viewBox="0 0 100 100" width="64" @click="toggleBurgerMenu">
                    <path class="line top" d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20" />
                    <path class="line middle" d="m 30,50 h 40" />
                    <path class="line bottom" d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20" />
                </svg>
            </div>
        </nav>
        <!-- <nav v-if="modal" v-on-click-outside="closeModal" class="burger-menu-content"> -->
        <nav class="burger-menu-content">
            <!-- <div class="theme-wrapper">
                <h2 class="menu-header-title">Color Theme</h2>
                <button @click="onClick()" class="toggle-container" aria-label="Toggle color mode" title="Toggle color mode">
                    <div class="sun"></div>
                    <div class="moon">
                        <div class="star"></div>
                        <div class="star small"></div>
                    </div>
                </button>
            </div> -->
            <div v-if="userStore.loginStatus==='success'" class="nav-content-wrapper">
                <!-- <hr> -->
                <div class="sm-wrapper nav">
                    <div :class="['rank rating-4', performanceStore.performance.campaign?.status?.currentStatus?.toLowerCase().replaceAll(' ','-')]">
                        <p v-if="userStore.isSalesAgent===true" class="rating-value">{{ ratingsStore.rating?.week?.bySalesAgent[0]?.average.ratings }}</p>
                        <img :src="'avatars/' + formattedSalesAgentName(incentiveInformations.name)" alt="SM Profilbild">
                    </div>
                    <div class="txt-wrapper"> 
                        <p class="sm-name">{{userStore.firstname + ' ' + userStore.surname}}</p>
                        <p class="sm-status">{{performanceStore.performance.campaign?.status?.currentStatus}}</p>
                    </div>
                </div>
                <hr>
                <div v-if="userStore.campaigns?.length>1" class="arrowWrapper campaign-select">
                    <button @click="toggleDropdown" class="dropdown-toggle form-child no-border" id="dropdown-toggle">
                        Kampagne wechseln
                    </button>
                    <ul :style="{ display: dropdownVisible ? 'block' : 'none' }" class="dropdown-menu" id="dropdown-menu">
                        <li class="headline dropdown-item">Standort || Kampagne:</li>
                        <li v-for="campaign of userStore.campaigns.filter((campaign) => campaign.id !==userStore.currentSelection.campaign?.id )" :key="campaign.id"
                            @click="handleChangeCampaign(campaign)"
                            :class="['dropdown-item submenu']">
                            <div>{{ `${campaign.company.name} || ${campaign.name}` }}</div>
                        </li>
                    </ul>
                </div>
                <hr>
                <ul class="main-nav">
                    <li><router-link @click="toggleBurgerMenu" to="/dashboard">Dashboard</router-link>
                        <ul>
                            <li><router-link @click="toggleBurgerMenu" to="incentives">Champions</router-link></li>
                            <li><router-link :class="[{'deactivated' : (exceededToDos && (exceededToDos.feedbacks===true || exceededToDos.followUps===true))}]" @click="toggleBurgerMenu" to="businessPartnerList?filter=id:-1|idSelection:-1|sort:businesspartner.name,asc&page=0">Potential & BK</router-link>
                                <ul>
                                    <li><router-link :class="[{'deactivated' : businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#ToDo:Wiedervorlagen`).length === 0}]" @click="toggleBurgerMenu" to="/salesPitchFollowUp">Wiedervorlagen To-Do <span class="number-label">{{ businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#ToDo:Wiedervorlagen`).length}}</span></router-link></li>
                                    <li><router-link :class="[{'deactivated' : businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#ToDo:VorOrtTermine`).length === 0}]" @click="toggleBurgerMenu" to="/salesPitchFeedback">Termin-Feedback <span class="number-label">{{ businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#ToDo:VorOrtTermine`).length}}</span></router-link></li>
                                </ul>
                            </li>
                            <li><router-link @click="toggleBurgerMenu" to="activity">Aktivitäten</router-link></li>
                            <li><router-link class="deactivated" @click="toggleBurgerMenu" to="performance">Performance</router-link></li>
                            <li><router-link class="deactivated" @click="toggleBurgerMenu" to="reviews">Bewertungen</router-link></li>
                        </ul>
                    </li>
                    <li><router-link class="deactivated" @click="toggleBurgerMenu" to="reviews">Google Ads Kostenrechner</router-link></li>
                </ul>
                <hr>
                <button @click="handleLogOut()" class="btn-primary">Logout</button>
            </div>
        </nav>
    </header>
</template>