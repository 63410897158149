<script setup>
    // import { ref, onMounted, computed } from 'vue';
    import { ref, onMounted, onUnmounted, watch, computed } from 'vue';

    const props = defineProps({
        percentValue: {
            type: Number,
            default: 0,
        },
        forecastValue: {
            type: Number,
            default: 0,
        },
        points: {
            type: Number,
            default: 0,
        },
        mode: {
            type: String,
            default: 'percentage',
        }
    })

    const percentValue = ref(props.percentValue);
    const height = ref(props.forecastValue);
    const top = computed(() => {
        if (props.percentValue > 100) {
            return '0%';
        } else if (props.percentValue < -100) {
            return '200%';
        } else {
            return `${100 - props.percentValue}%`;
        }
    });
    const computedHeight = computed(() => {
        if (props.percentValue <= 100) {
            return '100%';
        } else {
            return `${200 - props.percentValue}%`;
        }
    });

    const bubbleColor = computed(() => changeBubbleColor(props.percentValue));
    const progressElements = ref([]);

    // watch(() => props.percentValue, (newValue) => {
    //     percentValue.value = newValue;
    //     forecastValue.value = newValue;
    // });

    function changeBubbleColor(value) {
        // const colorInc = 100 / 3;
        // if (value < colorInc) {
        //     return 'red';
        // } else if (value < colorInc * 2) {
        //     return 'orange';
        // } else {
        //     return 'green';
        // }

        // const colorInc = 100 / 2;
        if (value < 100) {
            return 'red';
        } else {
            return 'green';
        }
    }

    const textColor = computed(() => {
        if (props.percentValue < 55) {
            return '#000';
        }  else {
            return '#fff';
        }
    });


    
    // const intervalId = ref(null);
    // function increasePercentValue() {
    //     intervalId.value = setInterval(() => {
    //         if (percentValue.value < 200) {
    //             percentValue.value += 1;
    //         } else {
    //             percentValue.value = 0;
    //         }
    //     }, 100000);
    // }

    // onMounted(() => {
    //     increasePercentValue();
    // });

    // onUnmounted(() => {
    //     if (intervalId.value) {
    //         clearInterval(intervalId.value);
    //     }
    // });

</script>

<template>
    <div class="circle-container positive">
        <div class="circle-inner"></div>
        <div class="circle-inner-second"></div>
        <div class="circle-inner-third"></div>
        <div :class="bubbleColor">
            <div class="progress">
                <div class="inner">
                    <div class="water" :style="{ top: top }"></div>
                    <div class="water" :style="{ top: top }"></div>
                    <div class="water" :style="{ top: top }"></div>
                    <div v-if="props.mode === 'points'" class="percent" :style="{ color: textColor }"><span>{{ props.points }}</span></div>
                    <div v-if="props.mode === 'percentage'" class="percent" :style="{ color: textColor }"><span>{{ props.percentValue }}</span>%</div>
                    <div class="glare"></div>
                </div>
            </div>
        </div>
        <div class="forecast-wrapper">
            <div class="circle-inner-fourth" :style="{ height: computedHeight }"></div>
        </div>
        <div class="circle-bg-container">
            <div class="circle-bg"></div>
            <div class="circle-bg"></div>
        </div>
        <div class="circle-border"></div>
    </div>
    <!-- <span>Enter Percentage: <input type="text" placeholder="67" id="percent-box">%</span> -->
</template>

<!-- <style src="@/scss/bubbles.scss" scoped></style> -->
