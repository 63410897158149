import { defineStore } from "pinia";
import { ref } from 'vue'

import { useUserStore } from "./user";

import { useActivityStore } from "./activity";

import { setKey as setBrowserStorageKey } from "./helpers/localStorage.mjs";
import { fetchFromBackend } from "./helpers/backend.mjs";



export const useBusinessPartnersStore = defineStore("businessPartners", {
  state: () => {
    return {
      businessPartnerLists: [], // a list of businessPartners defined by a specific filter (search)
      businessPartners: [], // single businessPartners with all their data
    }
  },
  getters: {
    businessPartnerList: (state) => {
      const userStore = useUserStore();
      const businessPartnerListExists = state.businessPartnerLists.find((list) => list.signature === userStore.currentSelection.businessPartnerList.signature);
      return (businessPartnerListExists) ? businessPartnerListExists : { summary: '', data: [], size: 0 };
    },
    businessPartnerListBySignature: (state) => (signature) => {
      const userStore = useUserStore();
      const businessPartnerListExists = state.businessPartnerLists.find((list) => list.signature === signature);
      return (businessPartnerListExists) ? businessPartnerListExists.data : [];
    },
    businessPartnerLeadLists: (state) => {
      const userStore = useUserStore();
      const lists = [];
      const listsInLeadSegments = [];
      const businessPartnerListExists = state.businessPartnerLists.find((list) => list.signature === `${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#LeadList`);
      // console.log('businessPartnerListExists', businessPartnerListExists)
      if (!businessPartnerListExists) return []
      for (const segment of userStore.filterOption.businessPartnerSegment.filter((segment) => ['Leads', 'Sugar Aktionen'].includes(segment.name))) {
        for (const list of segment.lists) {
          listsInLeadSegments.push(list);
        }
      }
      for (const businessPartner of businessPartnerListExists.data) {
        for (const businessPartnerList of businessPartner.lists) {
          const knownList = listsInLeadSegments.find((list) => list.name === businessPartnerList);
          if (!knownList) continue
          const listExists = lists.find((list) => list.id === knownList.id);
          if (!listExists) {
            lists.push(Object.assign({}, knownList, { businessPartners: [businessPartner] }));
          } else {
            listExists.businessPartners.push(businessPartner);
          }
        }
      }
      // console.log('listsInLeadSegments', listsInLeadSegments, 'lists', lists)
      return lists
    },
    businessPartner: (state) => {
      const userStore = useUserStore();
      const businessPartnerExists = state.businessPartners.find((businessPartner) => businessPartner.signature === `${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#${userStore.currentSelection.businessPartner.id}`);
      if (businessPartnerExists) {
        businessPartnerExists.data.contact.phoneMobile = (businessPartnerExists.data.contact.phoneList?.find((phone) => phone.type === 'mobile')) ? businessPartnerExists.data.contact.phoneList?.find((phone) => phone.type === 'mobile').name : null
        businessPartnerExists.data.contact.phoneStationary = (businessPartnerExists.data.contact.phoneList?.find((phone) => phone.type === 'stationary')) ? businessPartnerExists.data.contact.phoneList?.find((phone) => phone.type === 'stationary').name : null

        if (businessPartnerExists.data.contact.emailList.length === 0) businessPartnerExists.data.contact.emailList = [{ name: '-- unbekannt --' }];
        businessPartnerExists.data.contact.email = businessPartnerExists.data.contact.emailList[0].name;
      };
      return (businessPartnerExists) ? businessPartnerExists.data : null;
    },
    reminders: (state) => {
      const userStore = useUserStore();
      const reminders = {
        'Wiedervorlagen': [],
        'VorOrtTermine': []
      }
      for (const type of ['Wiedervorlagen', 'VorOrtTermine']) {
        const list = state.businessPartnerLists.find((list) => list.signature === `${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#Reminder:${type}`);
        // console.log('reminder list', list)
        if (!list || list.length === 0) continue
        for (const businessPartner of list.data) {
          const remainingMinutes = parseInt((new Date(businessPartner.salesPitch.dateFollowUp) - Date.now()) / (1000 * 60))
          // console.log('reminder businessPartner', businessPartner.name, remainingMinutes, 'in List', type)
          if (remainingMinutes < 600 && remainingMinutes > -15 && !reminders[type].find((reminder) => reminder.businessPartner.id === businessPartner.id)) {
            reminders[type].push({
              businessPartner: businessPartner,
              remainingMinutes: remainingMinutes,
              noAppointment: false,
              missed: remainingMinutes < 0
            })
          }
        }
      }
      reminders['VorOrtTermine'].sort((a, b) => a.remainingMinutes - b.remainingMinutes)
      reminders['Wiedervorlagen'].sort((a, b) => a.remainingMinutes - b.remainingMinutes)
      return reminders
    },
    namedFilter: (state) => (name) => {
      const userStore = useUserStore();
      // console.log('requesting namedFilter', name)
      let filter = {
        idCompany: userStore.currentSelection.campaign.company.id,
        idCampaign: userStore.currentSelection.campaign.id,
        idSalesAgent: (userStore.currentSelection.scope === 'salesAgent') ? userStore.currentSelection.salesAgent.id : -1,
        idSalesTeam: (userStore.currentSelection.scope === 'salesTeam') ? userStore.currentSelection.salesTeam.id : -1,
      };
      switch (name) {
        case 'Today:Wiedervorlagen':
          filter = Object.assign({}, filter, {
            page: 1,
            pages: 1,
            pageSize: 2500,
            id: -1,
            idBusinessPartnerStatus: 2,
            idBusinessPartnerOrder: 3,
            dateStart: new Date().toISOString().split('T')[0],
            dateEnd: new Date().toISOString().split('T')[0],
            order: 'asc',
            signature: `${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#Today:Wiedervorlagen`,
            summary: 'Today:Wiedervorlagen'
          });
          break;
        case 'Today:VorOrtTermine':
          filter = Object.assign({}, filter, {
            page: 1,
            pages: 1,
            pageSize: 2500,
            id: -1,
            idBusinessPartnerStatus: 3,
            idBusinessPartnerOrder: 3,
            dateStart: new Date().toISOString().split('T')[0],
            dateEnd: new Date().toISOString().split('T')[0],
            signature: `${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#Today:VorOrtTermine`,
            summary: 'Today:VorOrtTermine'
          });
          break;
        case 'Reminder:Wiedervorlagen':
          filter = Object.assign({}, filter, {
            page: 1,
            pages: 1,
            pageSize: 3,
            id: -1,
            idBusinessPartnerStatus: 2,
            idBusinessPartnerOrder: 3,
            order: 'asc',
            signature: `${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#Reminder:Wiedervorlagen`,
            summary: 'Reminder:Wiedervorlagen'
          });
          break;
        case 'Reminder:VorOrtTermine':
          filter = Object.assign({}, filter, {
            page: 1,
            pages: 1,
            pageSize: 1,
            id: -1,
            idBusinessPartnerStatus: 3,
            idBusinessPartnerOrder: 3,
            signature: `${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#Reminder:VorOrtTermine`,
            summary: 'Reminder:VorOrtTermine'
          });
          break;
        case 'ToDo:Wiedervorlagen':
          filter = Object.assign({}, filter, {
            page: 1,
            pages: 1,
            pageSize: 250,
            id: -2,
            idBusinessPartnerStatus: 2,
            idBusinessPartnerOrder: -1,
            signature: `${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#ToDo:Wiedervorlagen`,
            summary: 'Wiedervorlagen'
          });
          break;
        case 'ToDo:VorOrtTermine':
          filter = Object.assign({}, filter, {
            page: 1,
            pages: 1,
            pageSize: 250,
            id: -2,
            idBusinessPartnerStatus: 3,
            idBusinessPartnerOrder: -1,
            signature: `${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#ToDo:VorOrtTermine`,
            summary: 'Termin Feedback'
          });
          break;
        case 'LeadList':
          const leadLists = userStore.filterOption.businessPartnerLists.filter((list) => ['Leads', 'Sugar Aktionen'].includes(list.name));
          if (leadLists) {
            filter = Object.assign({}, filter, {
              page: 1,
              pages: 1,
              pageSize: 2500,
              id: -9, // new: not yet viewed
              idBusinessPartnerList: leadLists.map((leadList) => leadList.id).join(','),
              idBusinessPartnerStatus: 1,
              idBusinessPartnerOrder: -1,
              signature: `${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#LeadList`,
              summary: 'Aktuelle Leads'
            });
          }
          break;
        default:
          break;
      }
      // console.log('namedFilter', name, '=>', filter)
      return filter;
    }
  },
  actions: {
    /** start a search with a query, i.e. "Meier" for businessPartners
     * @async
    */
    async getBySearch() {
      const userStore = useUserStore();
      userStore.currentSelection.businessPartnerList.search.error = null;
      const filter = {
        idCompany: userStore.currentSelection.campaign.company.id,
        idCampaign: userStore.currentSelection.campaign.id
      }

      // return the existing data if it exists and its update property is less then 6 hours old and it matches the desired page
      let storedBusinessPartnerList = null;
      const signature = `${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#${userStore.currentSelection.businessPartnerList.search.mode}:${userStore.currentSelection.businessPartnerList.search.query}`;
      storedBusinessPartnerList = this.businessPartnerLists.find((businessPartnerList) => businessPartnerList.signature === signature);
      // console.log('businessPartner search with signature', signature, 'was found', storedBusinessPartnerList)
      // TODO: manage businessPartners in memory by updating existing data
      // if (storedBusinessPartnerList && storedBusinessPartnerList?.update > (Date.now() - (6 * 60 * 60 * 1000))) return storedBusinessPartnerList;

      // fetch the data from the backend
      const endpoint = "businessPartners/v1/getBusinessPartner";
      userStore.currentSelection.businessPartner.isLoading = true;
      const response = await fetchFromBackend(endpoint, {
        id: userStore.id,
        filter: filter,
        search: userStore.currentSelection.businessPartnerList.search.query,
        searchMode: userStore.currentSelection.businessPartnerList.search.mode,
      });
      userStore.currentSelection.businessPartner.isLoading = false;
      if (!response || !Array.isArray(response) || response.length === 0) return null;
      userStore.currentSelection.businessPartnerList.search.summary = `Suchergebnis für "${userStore.currentSelection.businessPartnerList.search.query}"`;
      let businessPartnerList = null;
      if (!storedBusinessPartnerList) {
        businessPartnerList = {
          signature: signature,
          summary: `Suchergebnis für "${userStore.currentSelection.businessPartnerList.search.query}"`,
          data: response.map((businessPartner) => formatBusinessPartner(businessPartner)),
          size: response.length,
          update: Date.now()
        }
        this.businessPartnerLists.push(businessPartnerList);
      } else {
        businessPartnerList = Object.assign(storedBusinessPartnerList, {
          data: response.map((businessPartner) => formatBusinessPartner(businessPartner)),
          size: response.length,
          update: Date.now()
        });
      }
      // a search always returns full businessPartner data, so we can store the resultig businessPartners and also update the list
      for (const businessPartner of businessPartnerList.data) {
        businessPartner.signature = `${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#${businessPartner.id}`;
        let storedBusinessPartner = this.businessPartners.find((storedBusinessPartner) => storedBusinessPartner.signature === businessPartner.signature);
        if (!storedBusinessPartner) {
          this.businessPartners.push({
            signature: businessPartner.signature,
            data: businessPartner,
            update: Date.now()
          });
        } else {
          storedBusinessPartner.update = Date.now();
          storedBusinessPartner.data = businessPartner;
        }
      }
      // console.log('returning from search with', businessPartnerList)
      userStore.currentSelection.businessPartnerList.signature = signature
      return businessPartnerList
    },
    /** start a search with a filter, i.e. { idBusinessPartnerList: "list:12" } for businessPartners either from Dashboard or from businessPartnerList
     * @async
     * @param {Object} filter - The filter object to search for businessPartners
     * @param {Array} searchCriteria - Additional search criteria to filter the businessPartners
    */
    async getByFilter(filter, searchCriteria = []) {
      const endpoint = "businessPartners/v1/getListByFilter";
      const userStore = useUserStore();

      // see if there is existing data if it exists and its update property is less then 6 hours old and it matches the desired page
      const storedBusinessPartnerList = this.businessPartnerLists.find((businessPartnerList) => businessPartnerList.signature === filter.signature);
      // console.log('getlistByFilter: checked signature', filter.signature, 'got stored:', storedBusinessPartnerList, 'filter is', filter)
      // TODO: manage Lists in memory by purging businessPartners from active lists
      // if (storedBusinessPartnerList && storedBusinessPartnerList?.update > (Date.now() - (6 * 60 * 60 * 1000))) return storedBusinessPartnerList;

      const payload = {
        id: userStore.id,
        filter: filter,
        view: {
          page: 1,
          pageSize: filter.pageSize * filter.pages,
          searchCriteria: searchCriteria,
          sort: {
            field: filter.idBusinessPartnerOrder,
          }
        },
      }
      if (filter.order) payload.view.sort.order = filter.order;

      if (filter.signature.includes('#Reminder:')) {
        // if querying a reminder, dont update the main businessPartnerList, also always update the list
        const response = await fetchFromBackend(endpoint, payload);
        if (!response.businessPartnerList) return false;
        if (!storedBusinessPartnerList) {
          // console.log('got a reminder businessPartnerList that was not stored', filter.signature, 'summary:', response.filter.summary)
          this.businessPartnerLists.push({ signature: filter.signature, filter: filter, searchCriteria: searchCriteria, sort: { id: filter.idBusinessPartnerOrder }, summary: response.filter.summary, data: response.businessPartnerList.map((businessPartner) => formatBusinessPartner(businessPartner)), size: response.view.countBusinessPartner, update: Date.now() });
        } else {
          // console.log('got a reminder businessPartnerList that was stored', filter.signature)
          storedBusinessPartnerList.update = Date.now();
          storedBusinessPartnerList.data = response.businessPartnerList.map((businessPartner) => formatBusinessPartner(businessPartner));
        }
      } else if (filter.signature.includes('#ToDo:') || filter.signature.includes('#LeadList')) {
        // if querying the toDos for the dashboard widget, always update the list
        const response = await fetchFromBackend(endpoint, payload);
        if (!response.businessPartnerList) return false;
        userStore.currentSelection.businessPartnerList.maxRows = response.view.countBusinessPartnerFiltered;
        if (!storedBusinessPartnerList) {
          // console.log('got a toDo/leads businessPartnerList that was not stored', filter.signature, 'summary:', response.filter.summary)
          this.businessPartnerLists.push({ signature: filter.signature, filter: filter, searchCriteria: searchCriteria, sort: { id: filter.idBusinessPartnerOrder }, summary: response.filter.summary, data: response.businessPartnerList.map((businessPartner) => formatBusinessPartner(businessPartner)), size: response.view.countBusinessPartner, update: Date.now() });
        } else {
          // console.log('got a toDo/leads businessPartnerList that was stored', filter.signature)
          storedBusinessPartnerList.update = Date.now();
          storedBusinessPartnerList.data = response.businessPartnerList.map((businessPartner) => formatBusinessPartner(businessPartner));
        }
      } else {
        // if querying a regular businessPartnerList (i.e. by filter) update the main businessPartnerList
        userStore.currentSelection.businessPartnerList.isLoading = true;
        const response = await fetchFromBackend(endpoint, payload);
        userStore.currentSelection.businessPartnerList.isLoading = false;
        userStore.currentSelection.businessPartnerList.maxRows = response.view.countBusinessPartnerFiltered;
        if (!response.businessPartnerList) return false;
        userStore.currentSelection.businessPartnerList.signature = filter.signature;
        userStore.currentSelection.businessPartnerList.page = filter.page;
        // if the data does not exists create it, if not update or expand it
        if (!storedBusinessPartnerList) {
          // console.log('getListByFilter, regularList: businessPartnerList was not stored', filter.signature, 'summary:', response.filter.summary)
          this.businessPartnerLists.push({ signature: filter.signature, filter: filter, searchCriteria: searchCriteria, sort: { id: filter.idBusinessPartnerOrder }, summary: response.filter.summary, data: response.businessPartnerList.map((businessPartner) => formatBusinessPartner(businessPartner)), size: response.view.countBusinessPartner, update: Date.now() });
        } else {
          storedBusinessPartnerList.update = Date.now();
          storedBusinessPartnerList.data = response.businessPartnerList.map((businessPartner) => formatBusinessPartner(businessPartner));
          // console.log('getListByFilter, regularList: replacing stored businessPartnerList', storedBusinessPartnerList.data.length, 'on', userStore.currentSelection.businessPartnerList.page, userStore.currentSelection.businessPartnerList.pageSize, storedBusinessPartnerList.data.length < (userStore.currentSelection.businessPartnerList.page * userStore.currentSelection.businessPartnerList.pageSize))
        }
      }
    },
    /** use an existing filter to expand a businessPartnerList i.e. { idBusinessPartnerList: "list:12" }
     * @async
    */
    async getByFilterExpand() {
      const endpoint = "businessPartners/v1/getListByFilter";
      const userStore = useUserStore();
      userStore.currentSelection.businessPartnerList.page++
      userStore.currentSelection.businessPartnerList.pages++

      const filter = Object.assign({}, userStore.currentFilter.filter, { page: userStore.currentSelection.businessPartnerList.page, pageSize: userStore.currentSelection.businessPartnerList.pageSize })
      const searchCriteria = userStore.currentFilter.searchCriteria

      // see if there is existing data if it exists and its update property is less then 6 hours old and it matches the desired page
      const storedBusinessPartnerList = this.businessPartnerLists.find((businessPartnerList) => businessPartnerList.signature === filter.signature);
      if (!storedBusinessPartnerList) return false;
      // console.log('getListByFilterExpand: checked signature', filter.signature, 'got stored:', storedBusinessPartnerList, 'filter is', filter)

      const payload = {
        id: userStore.id,
        filter: filter,
        view: {
          page: userStore.currentSelection.businessPartnerList.pages,
          pageSize: filter.pageSize,
          searchCriteria: searchCriteria,
          sort: {
            field: filter.idBusinessPartnerOrder,
          }
        },
      }
      if (filter.order) payload.view.sort.order = filter.order;
      // if querying a regular businessPartnerList (i.e. by filter) update the main businessPartnerList
      userStore.currentSelection.businessPartnerList.isLoading = true;
      const response = await fetchFromBackend(endpoint, payload);
      userStore.currentSelection.businessPartnerList.isLoading = false;
      userStore.currentSelection.businessPartnerList.maxRows = response.view.countBusinessPartnerFiltered;
      if (!response.businessPartnerList) return false;
      userStore.currentSelection.businessPartnerList.signature = filter.signature;
      storedBusinessPartnerList.update = Date.now();
      storedBusinessPartnerList.data = storedBusinessPartnerList.data.concat(response.businessPartnerList.map((businessPartner) => formatBusinessPartner(businessPartner)));
    },
    /** at campaign change or in certain intervals, the businessPartnerList data needs to be refreshed to reflect up to date 'Wiedervorlagen', 'vor Ort Termine' and 'Sugar Aktionen' 
     * @async
    */
    async refresh() {
      const userStore = useUserStore();
      // if (userStore.currentSelection.scope !== 'salesAgent') return false;
      const taskList = [
        this.getByFilter(this.namedFilter('ToDo:Wiedervorlagen')),
        this.getByFilter(this.namedFilter('ToDo:VorOrtTermine')),
        this.getByFilter(this.namedFilter('LeadList')),
        this.getByFilter(this.namedFilter('Reminder:Wiedervorlagen')),
        this.getByFilter(this.namedFilter('Reminder:VorOrtTermine')),
        this.getByFilter(this.namedFilter('Today:Wiedervorlagen')),
        this.getByFilter(this.namedFilter('Today:VorOrtTermine')),
        this.getByFilter(userStore.currentFilter.filter, userStore.currentFilter.searchCriteria)
      ];
      userStore.currentSelection.businessPartnerList.isLoading = true;
      await Promise.all(taskList);
      userStore.currentSelection.businessPartnerList.isLoading = false;
    },
    /** add a salesPitch to the businessPartner
     * @async
    */
    async saveSalesPitch() {
      const userStore = useUserStore();
      const activityStore = useActivityStore();
      // if no salesPitch was added, return false, else save the new salesPitch
      if (userStore.currentSelection.businessPartner.additionalSalesPitches === 0) return false;
      const additionalSalesPitch = userStore.currentSelection.businessPartner.additionalSalesPitches[userStore.currentSelection.businessPartner.additionalSalesPitches.length - 1];
      // console.log('additionalSalesPitch', additionalSalesPitch)
      // the products from the filterOptions are prefixed with product: so we need to remove it
      for (const offer of additionalSalesPitch.offerList) offer.product.id = parseInt(offer.product.id.toString().replace('product:', ''));
      // send the the data to the backend
      const endpoint = "businessPartners/v1/addSalesPitch";
      userStore.currentSelection.businessPartner.isLoading = true;
      const response = await fetchFromBackend(endpoint, {
        id: userStore.id,
        filter: Object.assign(userStore.currentSelection.businessPartnerList.filter, {
          idCompany: userStore.currentSelection.campaign.company.id,
          idCampaign: userStore.currentSelection.campaign.id
        }),
        businessPartner: Object.assign({}, this.businessPartner, { salesPitchList: [additionalSalesPitch] }),
      });
      await activityStore.refreshMode('week');
      userStore.currentSelection.businessPartner.isLoading = false;
      return response;
    },
    /** saves the feedback of a sales appointment by either closing or rescheduling the appointment
     * @async
    */
    async saveFeedback(businessPartner) {
      const userStore = useUserStore();
      const activityStore = useActivityStore();
      // if no salesPitch exists, return false, else save the new salesPitch
      if (businessPartner.salesPitchList.length === 0) return false;
      const lastSalesPitch = businessPartner.salesPitchList[businessPartner.salesPitchList.length - 1]
      // console.log('lastSalesPitch', lastSalesPitch)
      // the products from the filterOptions are prefixed with product: so we need to remove it
      for (const offer of lastSalesPitch.offerList) offer.product.id = parseInt(offer.product.id.toString().replace('product:', ''));

      // fetch the data from the backend
      const endpoint = "businessPartners/v1/addSalesPitch";
      userStore.currentSelection.businessPartner.isLoading = true;
      const response = await fetchFromBackend(endpoint, {
        id: userStore.id,
        filter: Object.assign(userStore.currentSelection.businessPartnerList.filter, {
          idCompany: userStore.currentSelection.campaign.company.id,
          idCampaign: userStore.currentSelection.campaign.id
        }),
        businessPartner: businessPartner,
      });
      await activityStore.refreshMode('week');
      userStore.currentSelection.businessPartner.isLoading = false;
      // TODO: error handling
      return (response === true) ? true : false;

    },
    /** reschedule a salesPitch of type "Wiedervorlage" of a businessPartner
     * @async
     * @param {Object} salesPitch - The salesPitch to reschedule
     * @return {Boolean} - True if the rescheduling was successful, false otherwise
    */
    async rescheduleSalesPitch(salesPitch) {
      const userStore = useUserStore();
      // fetch the data from the backend
      const endpoint = "businessPartners/v1/rescheduleSalesPitch";
      try {
        userStore.currentSelection.businessPartner.isLoading = true;
        await fetchFromBackend(endpoint, {
          id: userStore.id,
          salesPitch: {
            id: salesPitch.id,
            dateFollowUp: `${salesPitch.rescheduleDate}T${(salesPitch.rescheduleTime) ? salesPitch.rescheduleTime : '00:00'}:00.000Z`,
          }
        });
        userStore.currentSelection.businessPartner.isLoading = false;
        // if the rescheduling succeeded, remove the entry from the 'Wiedervorlagen' list
        const businessPartnerList = this.businessPartnerLists.find((list) => list.signature === `${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#ToDo:Wiedervorlagen`);
        // console.log('removing salePitch businessPartner', salesPitch.businessPartner.id, 'from list', businessPartnerList)
        businessPartnerList.data = businessPartnerList.data.filter((businessPartner) => businessPartner.id !== salesPitch.businessPartner.id);
        return true
      } catch (error) {
        userStore.currentSelection.businessPartner.isLoading = false;
        // console.log(error)
        return true
      }
    },
    /** after the salesPitch state of a businessPartner was change it is possible that the businessPartner needs to be removed from various lists
     * @param {Object} businessPartner - The businessPartner that was changed 
    */
    pruneBusinessPartnerLists(businessPartner) {
      // TODO: loop thrue all lists to see if the businessPartner
      this.businessPartnerLists = [];
    },
  },
});

/** a helper function to format/ expand the businessPartner data bejond the data that is returned from the backend
 * @param {Object} businessPartner
 * @return {Object} - The expanded businessPartner data
 */
function formatBusinessPartner(businessPartner) {
  const userStore = useUserStore();
  const data = Object.assign(businessPartner, { products: [], productGroups: [], contact: {}, offerList: [] });
  // console.log('formatBusinessPartner', data)
  // reorganize the contracts of the contract list to have the products grouped
  for (const contract of data.contractList || []) {
    const signature = `${contract.product.id}:${(contract.product.isOMP) ? 'OMP' : contract.publication.split(' ')[0]}`;
    let productExists = data.products.find((product) => product.signature === signature);
    if (!productExists) {
      productExists = {
        id: contract.product.id,
        signature: signature,
        name: contract.product.name,
        // name: `${contract.product.name}${(!contract.product.isOMP) ? ' [' + contract.publication.split(' ')[0] + ']' : ''}`,
        listAs: contract.product.listAs,
        sortOrder: contract.product.sortOrder,
        productGroup: { name: null, sortOrder: 0, reportingLink: null },
        contracts: []
      }
      if (contract.product.isOMP) {
        productExists.productGroup.name = 'OMP';
      } else {
        productExists.productGroup.name = contract.publication.split(' ')[0];
      }
      data.products.push(productExists);
    }
    productExists.contracts.push(contract);
    // console.log('contract', contract.id, 'added to product', productExists.name)
  }
  // console.log(`data.products 0 contracts ${(data.products[0]) ? data.products[0].contracts : null  }`, )
  // the status of the product is determined by the status of the latest contract
  for (const product of data.products) {
    product.contracts.sort((a, b) => parseInt(b.campaign.dateStart) - parseInt(a.campaign.dateStart));
    // status history = the most recent contract does not match 
    if (product.contracts[0].campaign.dateStart < userStore.currentSelection.campaign.dateStart) {
      product.status = 'history';
      continue;
    }
    // status active = the most recent contract has a status of 'Abo laufend'
    if (['Abo laufend'].includes(product.contracts[0].status.name?.trim())) {
      product.status = 'active';
      continue;
    }
    product.status = 'terminated';

    // TODO: evaluate if status terminated = the most recent contract has a status of 'Abo laufend'
    // if (['gekündigt / offen'].includes(product.contracts[0].status.name.trim())) {
    //   product.status = 'terminated';
    //   continue;
    // }
  }
  data.products = data.products.sort((a, b) => a.sortOrder - b.sortOrder);

  // based on wether the product is active or not, the reporting link is set
  if (data.products.find((product) => product.status === 'active' && product.productGroup.name === 'OE')) {
    data.productGroups.push({
      name: 'OE',
      reportingLink: `https://reporting.mon.coffee/KSTReporting/performanceMonthly?gpNo=${data.gpNo}&context=OETB`
    });
  }
  if (data.products.find((product) => product.status === 'active' && product.productGroup.name === 'TB')) {
    data.productGroups.push({
      name: 'TB',
      reportingLink: `https://reporting.mon.coffee/KSTReporting/performanceMonthly?gpNo=${data.gpNo}&context=TB`
    });
  }
  if (data.products.find((product) => product.status === 'active' && product.productGroup.name === 'GS')) {
    data.productGroups.push({
      name: 'GS',
      reportingLink: `https://reporting.mon.coffee/KSTReporting/performanceMonthly?gpNo=${data.gpNo}&context=GS`
    });
  }
  // set the reporting link for the full overview onl if there is a link for any of the productGroups
  if (data.productGroups.find((productGroup) => productGroup.reportingLink)) data.reportingLink = `https://reporting.mon.coffee/KSTReporting/performanceMonthly?gpNo=${data.gpNo}`;


  // depending on whether the businessPartner was queried from the getListByFilter or the getBusinessPartner endpoint, the data is structured differently
  // const salesPitch = (data.salesPitchList && Array.isArray(data.salesPitchList) && data.salesPitchList.length > 0) ? data.salesPitchList[data.salesPitchList.length - 1] : data.salesPitch;
  const salesPitch = data.salesPitch;

  data.contact = (salesPitch.contact) ? salesPitch.contact : null;
  data.offerList = (salesPitch.offerList) ? salesPitch.offerList : [];
  data.status = (salesPitch.status) ? salesPitch.status : null;
  // console.log('data', data)
  return data;
}