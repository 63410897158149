import { defineStore } from "pinia";

import { useUserStore } from "./user";

import { setKey as setBrowserStorageKey } from "./helpers/localStorage.mjs";
import { fetchFromBackend } from "./helpers/backend.mjs";

export const usePerformanceStore = defineStore("performance", {
  state: () => {
    return {
      performances: [],
    }
  },
  getters: {
    // return the full performance data, the signature allows for finding the performance by display (i.e. campaign or halfTime) and campaign.id
    performance: (state) => {
      if (!state.performances) return false;
      const userStore = useUserStore();
      const performance = state.performances.find((performance) => performance.signature === `sA:${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}`);
      if (!performance) return false;
      return performance.data;
    },
  },
  actions: {
    /** see if the incentives need to be fetched again, either because the data is not up to date or the display status has changed
    */
    async refresh() {
      const userStore = useUserStore();

      // console.log('refreshing performance with scope', userStore.currentSelection.scope)
      if (userStore.currentSelection?.scope !== 'salesAgent') return false;

      // create a signature to see if the data is is up to date or needs to be fetched
      const signature = (userStore.currentSelection.salesAgent.id !== -1) ? `sA:${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}` : `sT:${userStore.currentSelection.salesTeam.id}:${userStore.currentSelection.campaign.id}`;
      const performanceExists = this.performances.find((performance) => performance.signature === signature);
      // return the existing data if it exists and its update property is less then 6 hours old
      if (performanceExists && performanceExists?.update > (Date.now() - (6 * 60 * 60 * 1000))) return performanceExists;
      // fetch the data from the backend, get both halftime and campaign data
      const taskList = [
        fetchFromBackend("performanceRadar/v1/salesAgent", {
          id: userStore.id,
          filter: {
            mode: 'ht',
            idCompany: userStore.currentSelection.campaign.company.id,
            idCampaign: userStore.currentSelection.campaign.id,
            idSalesAgent: (userStore.currentSelection.scope === 'salesAgent') ? userStore.currentSelection.salesAgent.id : -1,
            idSalesTeam: (userStore.currentSelection.scope === 'salesTeam') ? userStore.currentSelection.salesTeam.id : -1,
          }
        }),
        fetchFromBackend("performanceRadar/v1/salesAgent", {
          id: userStore.id,
          filter: {
            mode: '',
            idCompany: userStore.currentSelection.campaign.company.id,
            idCampaign: userStore.currentSelection.campaign.id,
            idSalesAgent: (userStore.currentSelection.scope === 'salesAgent') ? userStore.currentSelection.salesAgent.id : -1,
            idSalesTeam: (userStore.currentSelection.scope === 'salesTeam') ? userStore.currentSelection.salesTeam.id : -1,
          }
        }),
      ];
      const responses = await Promise.all(taskList)
      userStore.currentSelection.performance.isLoading = false;
      if (!responses[0].length === 2 || !responses[0].salesAgentStatus || !responses[0].statusList || !responses[1].salesAgentStatus || !responses[1].statusList) return false;

      userStore.status = responses[0].salesAgentStatus
      // debug:
      // userStore.status.planned = 'Advanced';
      const status = responses[0].statusList.find((s) => s.name === userStore.status.planned);
      userStore.status.achieved = (status) ? status.achieved : false;
      const performanceData = {}
      for (const [index, segment] of ['halftime', 'campaign'].entries()) {
        const plannedStatus = responses[index].statusList.find((s) => s.name === responses[index].salesAgentStatus.planned);
        // console.log(
        //   'planned Status', plannedStatus, '\n',
        //   'performance setting status to', responses[0].salesAgentStatus, '\n',
        //   'statusList', responses[0].statusList, '\n',
        //   'searching', userStore.status.planned
        // )
        if (!plannedStatus) continue;
        performanceData[segment] = {
          status: {
            currentStatus: responses[0].salesAgentStatus.current,
            plannedStatus: responses[0].salesAgentStatus.planned,
            commission: responses[index].salesAgentStatus.commission,
            achieved: responses[index].salesAgentStatus.achieved,
            statusList: responses[index].statusList,
          },
          salesEuro: {
            planned: plannedStatus.plannedValues.salesEuro,
            current: responses[index].actualValues.salesEuro,
            expired: Math.floor(responses[index].actualValues.salesEuro / plannedStatus.plannedValues.salesEuro * 100)
          },
          salesEuroNewCustomers: {
            planned: plannedStatus.plannedValues.salesEuroNewCustomers,
            current: responses[index].actualValues.salesEuroNewCustomers,
            expired: Math.floor(responses[index].actualValues.salesEuroNewCustomers / plannedStatus.plannedValues.salesEuroNewCustomers * 100)
          },
          salesNumberNewCustomers: {
            planned: plannedStatus.plannedValues.salesNumberNewCustomers,
            current: responses[index].actualValues.salesNumberNewCustomers,
            expired: Math.floor(responses[index].actualValues.salesNumberNewCustomers / plannedStatus.plannedValues.salesNumberNewCustomers * 100)
          },
          progress: {
            planned: responses[index].progress.plannedTotal,
            current: responses[index].progress.plannedCurrent,
            expired: Math.floor(responses[index].progress.plannedCurrent / responses[index].progress.plannedTotal * 100)
          },
          units: responses[index].units
        }
      }
      // if the data exists update it, if not create it
      if (performanceExists) {
        Object.assign(performanceExists, { update: Date.now(), data: performanceData });
      } else {
        // console.log('pushing performance', performanceData)
        this.performances.push({ signature: signature, update: Date.now(), data: performanceData })
      }
    },
  },
});
