import { createWebHistory, createRouter } from "vue-router";
import { ref } from 'vue';

import Login from "@/views/Login.vue";
import Dashboard from "@/views/Dashboard.vue";
import Incentives from "@/views/SinglePages/Incentives.vue";
import Activity from "@/views/SinglePages/Activity.vue";
import Performance from "@/views/SinglePages/Performance.vue";
import Reviews from "@/views/SinglePages/Reviews.vue";
import BusinessPartnerList from "@/views/SinglePages/BusinessPartnerList.vue";
import BusinessPartner from "@/views/SinglePages/BusinessPartner.vue";
import SalesPitchFollowUp from "@/views/SinglePages/SalesPitchFollowUp.vue";
import SalesPitchFeedback from "@/views/SinglePages/SalesPitchFeedback.vue";

import { useUserStore } from "@/stores/user";
import { useBusinessPartnersStore } from '@/stores/businessPartners'

const rememberMe = localStorage.getItem('rememberMe')

const routes = [

    {
        path: import.meta.env.BASE_URL,
        name: "Login",
        component: Login,
        meta: {
            requiresLogin: false
        }
    },
    {
        name: "Dashboard",
        path: `/dashboard`,
        component: Dashboard,
        meta: {
            requiresLogin: true
        }
    },
    {
        name: "Incentives",
        path: `/incentives`,
        component: Incentives,
        meta: {
            requiresLogin: true
        }
    },
    {
        name: "Activity",
        path: `/activity`,
        component: Activity,
        meta: {
            requiresLogin: true
        }
    },
    {
        name: "Performance",
        path: `/performance`,
        component: Performance,
        meta: {
            requiresLogin: true
        }
    },
    {
        name: "SalesPitchFollowUp",
        path: `/salesPitchFollowUp`,
        component: SalesPitchFollowUp,
        meta: {
            requiresLogin: true
        }
    },
    {
        name: "SalesPitchFeedback",
        path: `/salesPitchFeedback`,
        component: SalesPitchFeedback,
        meta: {
            requiresLogin: true
        }
    },
    {
        name: "BusinessPartnerList",
        path: `/businessPartnerList`,
        component: BusinessPartnerList,
        meta: {
            requiresLogin: true
        }
    },
    {
        name: "BusinessPartner",
        path: `/businessPartner`,
        component: BusinessPartner,
        meta: {
            requiresLogin: true
        }
    },
    {
        name: "Reviews",
        path: `/reviews`,
        component: Reviews,
        meta: {
            requiresLogin: true
        }
    },
];

async function navigateTo(to, from, next) {
    const businessPartnersStore = useBusinessPartnersStore();
    const userStore = useUserStore();
    userStore.currentSelection.module = to.name;

    // set the searchError to false to prevent showing the error message from the previous searches
    userStore.currentSelection.businessPartnerList.search.error = false;

    let searchResult = null;
    switch (to.name) {
        case "BusinessPartner":
            if (to.query.source) userStore.currentSelection.businessPartnerList.source = to.query.source;
            userStore.currentSelection.businessPartnerList.search.mode = to.query.searchMode;
            userStore.currentSelection.businessPartnerList.search.query = to.query.search;
            // console.log('getListByFilter, route BusinessPartner: start searching businessPartnerList', userStore.currentSelection.businessPartner.isLoading)
            searchResult = await businessPartnersStore.getBySearch();
            // console.log('search with', to.query.search, 'returned result', searchResult);

            if (!searchResult) {
                // the search was not successful
                userStore.currentSelection.businessPartnerList.search.error = 'NO RESULTS'
                // console.log('search error', userStore.currentSelection.businessPartnerList.search.error);
                next(false);
            }
            if (searchResult && Array.isArray(searchResult.data) && searchResult.size === 1) {
                // the search returned exactly one businessPartner => route to the businessPartner details page and set the businessPartner to unseen = 0
                userStore.currentSelection.businessPartner.id = searchResult.data[0].id;
                const seenBusinessPartner = businessPartnersStore.businessPartnerList?.data?.find((businessPartner) => businessPartner.id === searchResult.data[0].id);
                if (seenBusinessPartner) seenBusinessPartner.unseen = 0;
                console.log('set to seen', searchResult.data[0].name, 'id', searchResult.data[0].id, seenBusinessPartner);
                // console.log('search with one result', searchResult.data[0].name, 'id', searchResult.data[0].id, 'source', userStore.currentSelection.businessPartnerList.source, 'searchResult', searchResult);
                next()
            }
            if (searchResult && Array.isArray(searchResult.data) && searchResult.size > 1) {
                // the search returned more then one businessPartner => route to the businessPartner list to allow for selecting the right businessPartner
                // console.log('search with multiple results', searchResult.size, 'source', userStore.currentSelection.businessPartnerList.source);
                next({ name: "BusinessPartnerList" });
            }
            break;
        case "BusinessPartnerList":
            if (to.query.source) userStore.currentSelection.businessPartnerList.source = to.query.source;
            // console.log('getListByFilter router: , route BusinessPartnerList', userStore.currentSelection.businessPartnerList.source, 'filter', to.query.filter, 'namedFilter', to.query.namedFilter, 'search', to.query.search);
            if (userStore.currentSelection.businessPartnerList.source === 'search') {
                // console.log('getListByFilter router: fetching with search', userStore.currentSelection.businessPartnerList.search.mode, userStore.currentSelection.businessPartnerList.search.query)
                searchResult = await businessPartnersStore.getBySearch();
            }
            if (userStore.currentSelection.businessPartnerList.source === 'filter') {
                let filter = null;
                let searchCriteria = null;
                if (!to.query.namedFilter) {
                    for (const item of to.query.filter?.split('|') || []) {
                        const [key, value] = item.split(':');
                        if (['idBusinessPartnerStatus'].includes(key) && userStore.currentSelection.businessPartnerList.filter[key]) userStore.currentSelection.businessPartnerList.filter[key] = parseInt(value);
                    }
                    filter = userStore.currentFilter.filter
                    searchCriteria = userStore.currentFilter.searchCriteria
                    // console.log('getListByFilter router: fetching with userStore filter', filter)
                } else {
                    filter = businessPartnersStore.namedFilter(to.query.namedFilter)
                    searchCriteria = []
                    console.log('getListByFilter router: fetching with named filter', to.query.namedFilter, ":", filter)
                }
                searchResult = await businessPartnersStore.getByFilter(filter, searchCriteria);
            }
            next();
            break;
        default:
            next();
    }
}

async function autoLogin(to, from, next) {
    const userStore = useUserStore();

    const savedEmail = localStorage.getItem('email')
    const savedPassword = localStorage.getItem('password')

    // console.log('auto login', rememberMe, savedEmail, savedPassword);

    if (rememberMe && savedEmail && savedPassword) {
        // console.log('auto login with call', savedEmail, savedPassword);

        userStore.isLoading = true;
        await userStore.login(savedEmail, savedPassword)
        userStore.isLoading = false;

        // console.log('!! auto login', userStore.idCampaignLogin);
        if (userStore.currentSelection.campaign.id === null) await userStore.selectCampaign(userStore.idCampaignLogin);
        await userStore.selectCampaign();

        await navigateTo(to, from, next);

    }
}

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth',
                top: 120,
            };
        } else if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    },
});

router.beforeEach(async (to, from, next) => {
    const userStore = useUserStore();

    userStore.currentSelection.module = to.name;

    if (rememberMe === 'true' && from.name === undefined) {
        // console.log("remember me", from.name, to.name);
        userStore.loginStatus = "success";
        await autoLogin(to, from, next);
        if (to.name === 'Login') {
            // console.log('1. route to', to.path);
            next({ name: "Dashboard" });
        } else {
            // console.log('2. route to', to.name);
            next();
        }
    } else if (to.meta.requiresLogin === false) {
        next();
    } else if (userStore.loginStatus !== "success" && !userStore.id) {
        if (to.path !== '') {
            next({ name: "Login" });
        } else {
            next()
        }
    } else {
        await navigateTo(to, from, next);
    }
});

export default router;